import {
  Card,
  CardContent,
  Grid,
  Typography,
  Divider,
  IconButton,
  Tooltip,
} from "@material-ui/core";
import LocalShippingIcon from "@material-ui/icons/LocalShipping";
import InsertPhotoIcon from "@material-ui/icons/InsertPhoto";
import React from "react";
import moment from "moment";
const renderEqualProps = (prevProps, nextProps) => {
  return JSON.stringify(prevProps) === JSON.stringify(nextProps);
};
const height = window.innerHeight;
const VehicleItemDisplay = (props) => {
  return (
    <tr>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-start",
          }}
        >
          {/* <LocalShippingIcon
                    style={{ color: props.color, fontSize: 40, marginRight: 5 }}
                  /> */}
          <td>
            <div style={{ width: (120 * height) / 801 }}>
              <Typography
                style={{
                  textAlign: "center",
                  fontWeight: "bold",
                  fontSize: (50 * height) / 801,
                  color: props.color,
                }}
              >
                {props.values.reference}
              </Typography>
            </div>
          </td>

          {/* <Divider
            orientation="vertical"
            flexItem
            style={{ marginRight: 10, marginLeft: 10 }}
          /> */}
          <td>
            <Typography style={{ fontSize: (45 * height) / 801 }}>
              {props.values.vehicle_plate_number}
            </Typography>
          </td>

          <Divider
            orientation="vertical"
            flexItem
            style={{ marginRight: 10, marginLeft: 10 }}
          />
          <Typography>NGC EXPRESS</Typography>
          <Divider
            orientation="vertical"
            flexItem
            style={{ marginRight: 10, marginLeft: 10 }}
          />
          {props.values.jo_start_date ? (
            <Typography>
              TIME IN : {moment(props.values.jo_start_date).format(" HH:MM A")}
            </Typography>
          ) : (
            ""
          )}
          <Divider
            orientation="vertical"
            flexItem
            style={{ marginRight: 10, marginLeft: 10 }}
          />
          <Typography>WAITING</Typography>
        </div>
        <div>
          <Typography
            style={{
              textAlign: "center",
              fontWeight: "bold",
              fontSize: (60 * height) / 801,
              color: props.color,
            }}
          >
            {props.values.reference}
          </Typography>
          {/* <IconButton>
                    <Tooltip title="View Image">
                      <InsertPhotoIcon />
                    </Tooltip>
                  </IconButton> */}
        </div>
      </div>
    </tr>
  );
};

export default React.memo(VehicleItemDisplay, renderEqualProps);
