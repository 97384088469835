import React from "react";
import { cancelRequest, getData } from "../../components/api/api";
import { useSpeechSynthesis } from "react-speech-kit";
import { useSelector } from "react-redux";
const QueuingListHooks = () => {
  const { speak, cancel, speaking, supported, voices } = useSpeechSynthesis();
  const refeshDisplay = useSelector(
    (state) => state.queueing_reducer.refeshDisplay
  );
  const messageNoti = useSelector(
    (state) => state.queueing_reducer.messageNoti
  );
  const [state, setState] = React.useState({
    queueList: [],
    currentServing: [],
    counter: 0,
    voiceStatus: false,
  });

  const rate = 0.9;
  const pitch = 1;

  React.useEffect(() => {
    async function getInitial() {
      // await getCurrentServing();
      await getQueueingList();
      onSpeak(messageNoti);
    }
    getInitial();

    return () => cancelRequest();
  }, [refeshDisplay]);

  const getQueueingList = async () => {
    const data = {
      counter_type: "Trucking",
    };

    getData("queueing/getQueueingList", data).then((res) => {
      setState((prev) => ({
        ...prev,
        queueList: res.queueingList,
      }));
      let voice = "";
      voiceList.forEach((val) => {
        if (val.name == "Google US English") {
          voice = val;
        }
      });

      setTimeout(() => {
        getQueueingList();
      }, [30000]);
    });
  };
  const voiceList = voices;
  const getCurrentServing = async () => {
    getData("queueing/onGetCurrentDisplay").then((res) => {
      let voice = "";
      voiceList.forEach((val) => {
        if (val.name == "Google US English") {
          voice = val;
        }
      });
      setState((prev) => ({
        ...prev,
        currentServing: res.response,
      }));
    });
  };

  const onSpeak = (text) => {
    let voice = "";
    voiceList.forEach((val) => {
      if (val.name == "Google US English") {
        voice = val;
      }
    });
    if (text === "Enable Voice") {
      setState((prev) => ({
        ...prev,
        voiceStatus: true,
      }));
    }

    speak({
      text: text,
      voice,
      rate,
      pitch,
    });
  };
  return { ...state, onSpeak };
};

export default QueuingListHooks;
