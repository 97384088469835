const initialState = {
    page: 0,
    rowsPerPage: 10,
    from: new Date(),
    to: new Date(),
    search: "",
    type: "All",
    selectedUser: [],
    loading: false,
    all_pages: [],
    user_privilege: [],
    account_privilege: []
};
const dataReducer = (state = initialState, action) => {
    switch (action.type) {
        case "ACTION_PRIVILEGE":
            return {
                ...state,
                ...action.payload,
            };
        default:
            return state;
    }
};
export default dataReducer;
