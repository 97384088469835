import AppBar from "@material-ui/core/AppBar";
import Avatar from "@material-ui/core/Avatar";
import CssBaseline from "@material-ui/core/CssBaseline";
import Divider from "@material-ui/core/Divider";
import Drawer from "@material-ui/core/Drawer";
import IconButton from "@material-ui/core/IconButton";
import Link from "@material-ui/core/Link";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import AccessibleIcon from "@material-ui/icons/Accessible";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import CallSplitIcon from "@material-ui/icons/CallSplit";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import HomeOutlinedIcon from "@material-ui/icons/HomeOutlined";
import MapIcon from "@material-ui/icons/Map";
import SupervisedUserCircleIcon from "@material-ui/icons/SupervisedUserCircle";
import MenuIcon from "@material-ui/icons/Menu";
import MenuBookIcon from "@material-ui/icons/MenuBook";
import VpnKeyIcon from "@material-ui/icons/VpnKey";
import clsx from "clsx";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link as NewLink, useHistory } from "react-router-dom";
import { cancelRequest, getData } from "../api/api";
import LogoIMG from "../media/glogo.png";
import Routing from "../router";
import AccountTreeIcon from "@material-ui/icons/AccountTree";
import DirectionsCarIcon from "@material-ui/icons/DirectionsCar";
const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  appBar: {
    background: "#6AB04C",
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  hide: {
    display: "none",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9) + 1,
    },
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
}));

export default function MiniDrawer() {
  const classes = useStyles();
  const user_data = JSON.parse(window.sessionStorage.getItem("data"));
  const account_privilege = useSelector(
    (state) => state.privilege_reducer.account_privilege
  );
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const dispatch = useDispatch();
  const history = useHistory();
  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };
  const logout = () => {
    history.push("/");
    sessionStorage.clear();
    window.location.reload();
  };
  const imglogo = LogoIMG;
  React.useEffect(() => {
    getListPage();
    getAccountPrivilege();
    return () => {
      cancelRequest();
    };
  }, []);
  const getListPage = () => {
    getData("auth/getAllAccessPages").then((res) => {
      dispatch({
        type: "ACTION_PRIVILEGE",
        payload: {
          all_pages: res.getAllPageAccess,
        },
      });
    });
  };
  const getAccountPrivilege = () => {
    let data = {
      user_id: user_data.user_id,
    };
    getData("auth/getUserAccessPages", data).then((res) => {
      dispatch({
        type: "ACTION_PRIVILEGE",
        payload: {
          account_privilege: res.privilege,
        },
      });
    });
  };
  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar
        style={{
          zIndex: 2,
        }}
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            className={clsx(classes.menuButton, {
              [classes.hide]: open,
            })}
          >
            <MenuIcon />
          </IconButton>
          <Link href="/#/">
            <Avatar
              variant="square"
              alt="picture"
              src={imglogo}
              style={{ width: 60, height: "auto" }}
            />
          </Link>
          <Typography variant="h6" noWrap>
            GREEN MOVERS
          </Typography>
        </Toolbar>
      </AppBar>
      <Drawer
        style={{
          zIndex: 1,
        }}
        variant="permanent"
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          }),
        }}
      >
        <div className={classes.toolbar}>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === "rtl" ? (
              <ChevronRightIcon />
            ) : (
              <ChevronLeftIcon />
            )}
          </IconButton>
        </div>
        <Divider />
        <List
          component="nav"
          className={classes.liststyle}
          aria-label="mailbox folders"
        >
          {account_privilege.map((val) => {
            if (val.privilege === "1") {
              return (
                <NewLink
                  to={val.page_link}
                  style={{ textDecoration: "none", color: "black" }}
                >
                  <ListItem button onClick={handleDrawerClose}>
                    <ListItemIcon>
                      {val.page_name === "Home" && (
                        <HomeOutlinedIcon style={{ color: "#6AB04C" }} />
                      )}
                      {val.page_name === "Full Screen Map" && (
                        <MapIcon style={{ color: "#6AB04C" }} />
                      )}
                      {val.page_name === "Booking" && (
                        <MenuBookIcon style={{ color: "#6AB04C" }} />
                      )}
                      {val.page_name === "IndexDriver" && (
                        <AccessibleIcon style={{ color: "#6AB04C" }} />
                      )}
                      {val.page_name === "indexPassanger" && (
                        <AccountCircleIcon style={{ color: "#6AB04C" }} />
                      )}
                      {val.page_name === "Privilege" && (
                        <VpnKeyIcon style={{ color: "#6AB04C" }} />
                      )}
                      {val.page_title === "Queueing" && (
                        <CallSplitIcon style={{ color: "#6AB04C" }} />
                      )}
                      {val.page_title === "Partners" && (
                        <SupervisedUserCircleIcon
                          style={{ color: "#6AB04C" }}
                        />
                      )}
                      {val.page_title === "Vehicles" && (
                        <DirectionsCarIcon style={{ color: "#6AB04C" }} />
                      )}
                    </ListItemIcon>
                    <ListItemText primary={val.page_title} />
                  </ListItem>
                  <Divider />
                </NewLink>
              );
            }
          })}
          <NewLink to="/" style={{ textDecoration: "none", color: "black" }}>
            <ListItem button onClick={logout}>
              <ListItemIcon>
                <ExitToAppIcon style={{ color: "#6AB04C" }} />
              </ListItemIcon>
              <ListItemText primary="Logout" />
            </ListItem>
          </NewLink>
          <Divider />
          {/* <NewLink to='/' style={{ textDecoration: 'none', color: 'black' }}>
            <ListItem
              button
              onClick={() => {
                logout()
              }}
            >
              <ListItemIcon>
                <ExitToAppIcon style={{ color: '#6AB04C' }} />
              </ListItemIcon>
              <ListItemText primary='Log out' />
            </ListItem>
          </NewLink>
          <Divider /> */}
        </List>
      </Drawer>
      <main className={classes.content}>
        <div className={classes.toolbar} />
        <Routing />
      </main>
    </div>
  );
}
