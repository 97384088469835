const initialState = {
  GetDashboardDetails: [],
  GetDashboardDetailsStatus: "",
  GetDashboardDetailsDriver: [],
  GetDashboardDetailsTrips: [],
  SearchDashboardDetailsTrips: [],
  DriverpieArray: [],
  UniqueVehicles: [],
  TodayRev: 0,
  GetDashboardDetailsFilterDriver: [],
};
const dataReducer = (state = initialState, action) => {
  switch (action.type) {
    case "GetDashboardDetails":
      return {
        ...state,
        GetDashboardDetailsStatus: action.data.status,
        GetDashboardDetailsDriver: action.data.drivers,
        GetDashboardDetailsFilterDriver: action.data.drivers,
        GetDashboardDetailsTrips: action.data.trips,
        SearchDashboardDetailsTrips: action.data.trips,
      };
    case "DriverpieArray":
      return {
        ...state,
        DriverpieArray: action.data,
      };
    case "TodayRev":
      return {
        ...state,
        TodayRev: action.data,
      };
    case "UniqueVehicles":
      return {
        ...state,
        UniqueVehicles: action.data,
      };

    case "filtering":
      let DriverFilter = [];
      if (action.data.status === "ALL" && action.data.vehicle_type === "ALL") {
        DriverFilter = state.GetDashboardDetailsDriver;
      } else {
        DriverFilter = state.GetDashboardDetailsDriver.filter(
          (files) =>
            (action.data.status === "ALL" ||
              files.status === action.data.status) &&
            (action.data.vehicle_type === "ALL" ||
              files.vehicle_type === action.data.vehicle_type)
        );
      }
      // if(action.data.status === 'ALL'){
      // DriverFilter = state.GetDashboardDetailsDriver.filter(
      //   (files) =>
      //     files.status == action.data.status &&
      //     files.vehicle_type == action.data.vehicle_type
      // );
      // }
      return {
        ...state,
        GetDashboardDetailsFilterDriver: DriverFilter,
      };
    case "searchbooking":
      let BookingSearch = state.GetDashboardDetailsTrips.filter((files) => {
        console.log(files);
        return (
          files.client_fullname
            .toLowerCase()
            .indexOf(action.data.toLocaleLowerCase()) !== -1
        );
      });
      return {
        ...state,
        SearchDashboardDetailsTrips: BookingSearch,
      };
    case "bookingfiltering":
      let Booking = [];
      if (action.data === "All") {
        Booking = state.GetDashboardDetailsTrips;
      } else {
        Booking = state.GetDashboardDetailsTrips.filter(
          (files) => files.jo_type === action.data
        );
      }
      return {
        ...state,
        SearchDashboardDetailsTrips: Booking,
      };
    default:
      return state;
  }
};
export default dataReducer;
