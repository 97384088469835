import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { HashRouter as Router, Route } from "react-router-dom";
import "../App.css";
import Auth from "../components/auth";
import Navigation from "./navigation/navigation1.js";
import Backdrop from "./apps/reusesableComponents/Backdrop";
import Routes from "./routes";
import Gmovers from "./assets/gmovers.gif";
const drawerWidth = 240;
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  appBar: {
    background: "#2f3640",
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  hide: {
    display: "none",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: "flex-end",
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
}));

function App() {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const navigation_reducer = useSelector((state) => state.navigation_reducer);
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.booking_reducer.loading);
  const dispatch_data = (type, data) => {
    dispatch({
      type: type,
      data: data,
    });
  };
  return (
    <>
      <Backdrop open={loading} />
      <Router>
        <React.Suspense
          fallback={
            <img
              src={Gmovers}
              style={{ width: 200, height: 200, objectFit: "contain" }}
            />
          }
        >
          {Routes.map((value, index) => {
            return (
              <Route
                key={index}
                exact={value.exact}
                path={value.path}
                component={Auth(value.page_name)}
              />
            );
          })}
        </React.Suspense>
      </Router>
    </>
  );
}

export default App;
