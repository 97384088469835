const initialState = {
  page: 0,
  rowsPerPage: 10,
  from: new Date(),
  to: new Date(),
  search: "",
  type: "All",
  selectedDriver: [],
  loading: false,
};
const dataReducer = (state = initialState, action) => {
  switch (action.type) {
    case "ACTION_DRIVER":
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};
export default dataReducer;
