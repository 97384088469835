import React from "react";
import {
  Typography,
  Grid,
  Card,
  TableHead,
  TableCell,
  Table,
  TableBody,
  TableRow,
} from "@material-ui/core";
import VehicleItemDisplay from "../queueing/VehicleItemDisplay";
import moment from "moment";
const height = window.innerHeight;
const Preview = (props) => {
  return (
    <div>
      {" "}
      {/* <div
        style={{
          display: "flex",
          alignContent: "center",
          justifyContent: "center",
          paddingTop: 10,
          paddingBottom: 10,
          backgroundColor: "#508c37",
          marginBottom: 10,
          background: "#508c37",
        }}
      >
        <Typography style={{ color: "#fff", fontSize: 25 }}>
          Queue List
        </Typography>
      </div> */}
      <div>
        <Table>
          <TableHead>
            <TableCell
              style={{
                background: "#427a2b",
                color: "#fff",
                fontWeight: "bold",
              }}
            >
              Preview
            </TableCell>
            <TableCell
              style={{
                background: "#427a2b",
                color: "#fff",
                fontWeight: "bold",
              }}
            ></TableCell>
          </TableHead>
          <TableBody>
            {props.queueList.map((val, index) => {
              return (
                <TableRow>
                  <TableCell>
                    <Typography
                      style={{
                        textAlign: "center",
                        fontWeight: "bold",
                        fontSize: (20 * height) / 801,
                        // color: "2b",
                      }}
                    >
                      {val.reference}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    {" "}
                    <Typography
                      style={{
                        fontSize: (20 * height) / 801,
                        color: "#427a2b",
                        fontWeight: "bold",
                      }}
                    >
                      {val.vehicle_plate_number}
                    </Typography>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </div>
    </div>
  );
};

export default Preview;
