import React from "react";
import {
  Typography,
  Grid,
  Card,
  TableHead,
  TableCell,
  Table,
  TableBody,
  TableRow,
} from "@material-ui/core";
import VehicleItemDisplay from "../queueing/VehicleItemDisplay";
import moment from "moment";
const height = window.innerHeight;
const QueuingList = (props) => {
  return (
    <div>
      {" "}
      {/* <div
        style={{
          display: "flex",
          alignContent: "center",
          justifyContent: "center",
          paddingTop: 10,
          paddingBottom: 10,
          backgroundColor: "#508c37",
          marginBottom: 10,
          background: "#508c37",
        }}
      >
        <Typography style={{ color: "#fff", fontSize: 25 }}>
          Queue List
        </Typography>
      </div> */}
      <div>
        <Table size="small">
          <TableHead>
            <TableCell
              style={{
                background: "#6AB04C",
                height: 40,
                color: "#fff",
                fontWeight: "bold",
              }}
            >
              NO.
            </TableCell>
            <TableCell
              style={{
                background: "#6AB04C",
                color: "#fff",
                fontWeight: "bold",
              }}
            >
              PLATE NO.
            </TableCell>
            <TableCell
              style={{
                background: "#6AB04C",
                color: "#fff",
                fontWeight: "bold",
              }}
            >
              COMPANY
            </TableCell>
            <TableCell
              style={{
                background: "#6AB04C",
                color: "#fff",
                fontWeight: "bold",
              }}
            >
              VEHICLE TYPE
            </TableCell>
            <TableCell
              style={{
                background: "#6AB04C",
                color: "#fff",
                fontWeight: "bold",
              }}
            >
              ARRIVAL
            </TableCell>
            {/* <TableCell
              style={{
                background: "#6AB04C",
                color: "#fff",
                fontWeight: "bold",
              }}
            >
              STATUS
            </TableCell> */}
            <TableCell
              style={{
                background: "#6AB04C",
                color: "#fff",
                fontWeight: "bold",
              }}
            >
              Loading Bay
            </TableCell>
          </TableHead>
          <TableBody>
            {props.queueList.map((val, index) => {
              return (
                <TableRow>
                  <TableCell>
                    <Typography
                      style={{
                        textAlign: "center",
                        fontWeight: "bold",
                        fontSize: (35 * height) / 801,
                        // color: "2b",
                      }}
                    >
                      {val.reference}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    {" "}
                    <Typography
                      style={{
                        fontSize: (30 * height) / 801,
                        color: "#6AB04C",
                        fontWeight: "bold",
                      }}
                    >
                      {val.vehicle_plate_number}
                    </Typography>
                  </TableCell>

                  <TableCell>
                    {" "}
                    <Typography style={{ fontSize: (30 * height) / 801 }}>
                      {val.company_name}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    {" "}
                    <Typography style={{ fontSize: (25 * height) / 801 }}>
                      {String(val.vehicle_type).toLocaleUpperCase()}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    {" "}
                    {val.jo_date_added ? (
                      <Typography style={{ fontSize: (25 * height) / 801 }}>
                        {moment(val.jo_date_added).format("hh:mm A")}
                      </Typography>
                    ) : (
                      ""
                    )}
                  </TableCell>
                  {/* <TableCell>
                    {" "}
                    <div
                      style={{
                        backgroundColor: "#2c3e50",
                        padding: (6 * height) / 801,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        borderRadius: (30 * height) / 801,
                        color: "#fff",
                      }}
                    >
                      <Typography
                        style={{
                          fontSize: (20 * height) / 801,
                        }}
                      >
                        QUEUE
                      </Typography>
                    </div>
                  </TableCell> */}
                  <TableCell>
                    {" "}
                    <Typography
                      style={{
                        fontSize: (35 * height) / 801,
                        fontWeight: "bold",
                        color: "#e74c3c",
                      }}
                    >
                      {val.counter_number ? (
                        String(val.counter_number).toLocaleUpperCase()
                      ) : (
                        <span style={{ visibility: "hidden" }}>0</span>
                      )}
                    </Typography>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </div>
    </div>
  );
};

export default QueuingList;
