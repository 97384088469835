import { Grid, Typography, Tooltip } from "@material-ui/core";
import React from "react";
import CurrentServing from "./CurrentServing";
import VehicleList from "../queueing/VehicleList";
import QueuingList from "./QueuingList";
import QueuingListHooks from "../../../hooks/queueingHooks/QueuingListHooks";
import Preview from "./PreviewList";
import RecordVoiceOverIcon from "@material-ui/icons/RecordVoiceOver";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import { useHistory } from "react-router-dom";
const height = window.innerHeight;

const IndexQueuingDisplay = () => {
  const history = useHistory();
  const { ...param } = QueuingListHooks();
  const logout = () => {
    history.push("/");
    sessionStorage.clear();
    window.location.reload();
  };
  return (
    <div style={{ padding: 10 }}>
      <Grid container spacing={1}>
        <Grid item xs={12} md={7}>
          {/* <button id="voice" onClick={param.onSpeak}>
            tets
          </button> */}
          <Tooltip title="Logout">
            <ExitToAppIcon
              style={{ color: "#6AB04C", marginRight: 10, cursor: "pointer" }}
              id="voice"
              onClick={logout}
            />
          </Tooltip>
          <Tooltip title="Voice Enable">
            <RecordVoiceOverIcon
              style={{
                color: param.voiceStatus ? "#3498db" : "#95a5a6",
                cursor: "pointer",
              }}
              id="voice"
              onClick={() => param.onSpeak("Enable Voice")}
            />
          </Tooltip>
          {/* <CurrentServing currentServing={param.currentServing} /> */}
        </Grid>
        <Grid item xs={12} md={12}>
          <QueuingList queueList={param.queueList} />
        </Grid>
        {/* <Grid item xs={12} md={3}>
          <Preview queueList={param.queueList} />
        </Grid> */}
      </Grid>
    </div>
  );
};

export default IndexQueuingDisplay;
