import { initializeApp } from "firebase/app";
import { getMessaging, onMessage, getToken } from "firebase/messaging";
var firebaseConfig = {
  apiKey: "AIzaSyDSpTmNRSCnV9p7txKutSppxEo9F_9pjWY",
  authDomain: "store-front-5c855.firebaseapp.com",
  projectId: "store-front-5c855",
  storageBucket: "store-front-5c855.appspot.com",
  messagingSenderId: "651654358045",
  appId: "1:651654358045:web:4fdaf4b00e4abca2c8d2f4",
  measurementId: "G-V7PHGX2Y6W",
};

const firebaseApp = initializeApp(firebaseConfig);
const messaging = getMessaging(firebaseApp);

const { REACT_APP_VAPID_KEY } = process.env;
const publicKey = REACT_APP_VAPID_KEY;
export const getTokenVal = async (setTokenFound, setTokeValue) => {
  let currentToken = "";

  try {
    currentToken = await getToken(messaging, {
      vapidKey:
        "BORXV1tfbPnp5y5QF5cluZGH6tj6nfw5s31B9TGdNlT8k7ElsFIrwo4Vu0MHDbTasCJHgGWMwrN57rcEzntdsxc",
    });
    if (currentToken) {
      setTokeValue(currentToken);
      // insertToken()
      setTokenFound(true);
    } else {
      setTokenFound(false);
    }
  } catch (error) {
    console.log("An error occurred while retrieving token. ", error);
  }

  return currentToken;
};

export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      resolve(payload);
    });
  });
