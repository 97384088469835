import { Grid, Typography } from "@material-ui/core";
import React from "react";
import moment from "moment";
const height = window.innerHeight;

const CurrentServing = (props) => {
  return (
    <div style={{ position: "fixed", width: "57%" }}>
      <Grid continer spacing={1}>
        <Grid item xs={12} md={12}>
          <div
            style={{
              height: height * 0.95,
              flexDirection: "column",
            }}
          >
            <div>
              {" "}
              <div>
                <Typography
                  style={{
                    color: "#508c37",
                    fontWeight: "bold",
                    fontSize: (50 * height) / 801,
                    // borderBottomWidth: 5,
                    // borderBottomStyle: "solid",
                    // borderBottomColor: "#508c37",
                  }}
                >
                  Counter 1
                </Typography>
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  flexDirection: "column",
                }}
              >
                <Typography
                  style={{
                    fontWeight: "bold",
                    // color: "#f39c12",

                    fontSize: (40 * height) / 801,
                  }}
                >
                  NOW SERVING
                </Typography>
                <Typography
                  style={{
                    fontWeight: "bold",
                    color: "#508c37",
                    fontSize: (290 * height) / 801,
                    visibility: props.currentServing[0]?.queue_name
                      ? undefined
                      : "hidden",
                  }}
                >
                  {props.currentServing[0]?.queue_name
                    ? props.currentServing[0]?.queue_name
                    : 0}
                </Typography>
              </div>
              <div
                style={{
                  padding: (10 * height) / 801,
                  borderRadius: (10 * height) / 801,
                  borderWidth: (4 * height) / 801,
                  borderStyle: "solid",
                  borderColor: "#508c37",
                }}
              >
                <Grid container spacing={1}>
                  <Grid item xs={12} md={6}>
                    <Typography
                      style={{
                        fontSize: (22 * height) / 801,
                        fontWeight: "bold",
                      }}
                    >
                      Plate No.
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Typography
                      style={{
                        fontSize: (22 * height) / 801,
                        fontWeight: "bold",
                      }}
                    >
                      {props.currentServing[0]?.vehicle_plate_number}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Typography
                      style={{
                        fontSize: (22 * height) / 801,
                        fontWeight: "bold",
                      }}
                    >
                      Vehicle Type
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Typography
                      style={{
                        fontSize: (22 * height) / 801,
                        fontWeight: "bold",
                      }}
                    >
                      {props.currentServing[0]?.vehicle_type}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Typography
                      style={{
                        fontSize: (22 * height) / 801,
                        fontWeight: "bold",
                      }}
                    >
                      Contactor
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Typography
                      style={{
                        fontSize: (22 * height) / 801,
                        fontWeight: "bold",
                      }}
                    >
                      {props.currentServing[0]?.sender_name}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Typography
                      style={{
                        fontSize: (22 * height) / 801,
                        fontWeight: "bold",
                      }}
                    >
                      Time In
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Typography
                      style={{
                        fontSize: (22 * height) / 801,
                        fontWeight: "bold",
                      }}
                    >
                      {props.currentServing[0]?.serve_date_start
                        ? moment(
                            props.currentServing[0]?.serve_date_start
                          ).format("HH:MM A")
                        : ""}
                    </Typography>
                  </Grid>
                </Grid>
              </div>
            </div>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default CurrentServing;
