import React from "react";
import "../App.css";

const Booking = React.lazy(() => import("./apps/bookingV2/IndexBooking"));
const Index = React.lazy(() => import("./apps/landing/landing"));
const FullScreenMap = React.lazy(() =>
  import("./apps/fullscreen_map/fullscreen_map")
);
const IndexDashboard = React.lazy(() =>
  import("./apps/dashboard/IndexDashboard")
);
const IndexBooking = React.lazy(() => import("./apps/bookingV2/IndexBooking"));
const IndexDriver = React.lazy(() => import("./apps/drivers/IndexDriver"));
const IndexPassengers = React.lazy(() =>
  import("./apps/passengers/IndexPassengers")
);
const Login = React.lazy(() => import("./apps/login/loginIndex"));
const IndexQueueing = React.lazy(() => import("./apps/queueing/IndexQueueing"));
const Privilege = React.lazy(() => import("./apps/privilege/indexPrivilege"));
const IndexPartners = React.lazy(() => import("./apps/partners/IndexPartners"));
const IndexVehicle = React.lazy(() => import("./apps/vehicles/IndexVehicle"));
function App(page) {
  if (page === "Home") {
    return IndexDashboard;
  }
  if (page === "Full Screen Map") {
    return FullScreenMap;
  }
  if (page === "Booking") {
    return Booking;
  }
  if (page === "Dashboard") {
    return IndexDashboard;
  }
  if (page === "IndexDriver") {
    return IndexDriver;
  }
  if (page === "IndexPassengers") {
    return IndexPassengers;
  }
  if (page === "Login") {
    return Login;
  }
  if (page === "IndexQueueing") {
    return IndexQueueing;
  }
  if (page === "Privilege") {
    return Privilege;
  }
  if (page === "IndexPartners") {
    return IndexPartners;
  }
  if (page === "IndexVehicle") {
    return IndexVehicle;
  }
  // if (matchPage == true) {
  //     if (page == 'Client Accomplishments') {
  //         return (Accom)
  //     }
  //     else if (page == 'Accomplished') {
  //         return (Accomplish)
  //     }
  //     else if (page == 'Monitoring Accomplishment') {
  //         return (MontAccom)
  //     }
  //     else if(page == 'Upload Data'){
  //         return (UploadFile);
  //     }
  //     else if(page == 'Reports'){
  //         return (Reports);
  //     }
  //     else if (page == 'Assigning Job Orders') {
  //         return (Assigning)
  //     }
}

export default App;
