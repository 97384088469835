import logo from "./logo.svg";
import React from "react";
import "./App.css";
import {
  HashRouter as Router,
  Route,
  Redirect,
  Routes,
} from "react-router-dom";
import Routing from "./components/router";
import Navigation from "./components/navigation/navigation";
import IndexQueuingDisplay from "./components/apps/queuingDisplay/IndexQueuingDisplay";
import { useState } from "react";
import { getTokenVal, onMessageListener } from "./components/firebase";

import Login from "./components/apps/login/loginIndex";
import { cancelRequest, getData } from "./components/api/api";
import { useDispatch, useSelector } from "react-redux";
function App() {
  const split = window.location.href.split("/");
  const [show, setShow] = useState(false);
  const [notification, setNotification] = useState({ title: "", body: "" });
  const [isTokenFound, setTokenFound] = useState(false);
  const [token, setTokeValue] = useState("");
  const dispatch = useDispatch();
  const refeshDisplay = useSelector(
    (state) => state.queueing_reducer.refeshDisplay
  );
  getTokenVal(setTokenFound, setTokeValue);

  onMessageListener()
    .then((payload) => {
      console.log(payload);
      if (payload.notification.body == "Refresh") {
        dispatch({
          type: "ACTION_QUEUEING",
          payload: {
            refeshDisplay: !refeshDisplay,
            messageNoti: payload.notification.title,
          },
        });
      }

      console.log(payload);
      // setShow(true);
      // setNotification({
      //   title: payload.notification.title,
      //   body: payload.notification.body,
      // });
    })
    .catch((err) => console.log("failed: ", err));
  React.useEffect(() => {
    let userLogin = sessionStorage.getItem("data");
    let user_id = "";
    if (userLogin != null) {
      userLogin = JSON.parse(userLogin);
      user_id = userLogin?.user_id;
    }
    const data = {
      user_id: user_id,
      web_device_token: token,
    };
    getData("queueing/onSaveDeviceToken", data).then((res) => {});
    return () => cancelRequest();
  }, [token]);

  return sessionStorage.getItem("data") === null ? (
    <Router>
      <Route path="/" component={Login} />
      <Route path="/login/" component={Login} />
    </Router>
  ) : (
    <Router>
      {split[4] != "queuingDisplay" ? (
        <Route path="/" component={Navigation} />
      ) : null}

      <Route path="/queuingDisplay" component={IndexQueuingDisplay} />
    </Router>
  );
}

export default App;
