import { useDispatch } from "react-redux";

import Avatar from "@material-ui/core/Avatar";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import Grid from "@material-ui/core/Grid";
import Link from "@material-ui/core/Link";
import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import React from "react";
import { useHistory } from "react-router-dom";
import { getData } from "../../api/api";

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {"Copyright © "}
      <Link color="inherit" href="https://material-ui.com/">
        Gzonetech Inc.
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100vh",
  },
  image: {
    backgroundImage:
      "url(https://scontent.fmnl4-4.fna.fbcdn.net/v/t1.6435-9/129055723_103764561595088_3669844006464424638_n.jpg?_nc_cat=102&ccb=1-7&_nc_sid=09cbfe&_nc_eui2=AeE5ET5kn4LS12ZsKxOPK4qA72NNFZBCMizvY00VkEIyLEZTxDpuafWoOiokE9PtW0dUVPOlSuxpXdoi_CxlXqq3&_nc_ohc=seE0270DF44AX8Q9Upq&_nc_ht=scontent.fmnl4-4.fna&oh=00_AfC0IxuGwlNOfhw0WkjVq7Xss4QXI4mgDTTKAkjtOuhOPw&oe=647A7A22)",
    backgroundRepeat: "no-repeat",
    backgroundColor:
      theme.palette.type === "light"
        ? theme.palette.grey[50]
        : theme.palette.grey[900],
    backgroundSize: "cover",
    backgroundPosition: "center",
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: "#2ecc71",
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    backgroundColor: "#2ecc71",
    color: "white",
    "&:hover": {
      backgroundColor: "#2ecc71",
      color: "white",
    },
  },
}));

export default function Login() {
  const dispatch = useDispatch();
  const history = useHistory();
  const [state, setState] = React.useState({
    username: "",
    password: "",
  });
  const onChange = (event) => {
    setState({
      ...state,
      [event.target.name]: event.target.value,
    });
  };
  const onSubmit = (e) => {
    e.preventDefault();
    let data = {
      password: state.password,
      username: state.username,
      account_type: "Admin",
      app_id: 1,
      device_id: "",
      version: "",
    };
    getData("auth/login_mobile", data).then((res) => {
      console.log(res);
      if (res.status === "Success") {
        let privilege = res.privilege;
        sessionStorage.setItem("data", JSON.stringify(res.login[0]));
        privilege.forEach((element, index) => {
          if (index === 0) {
            history.push(element.page_link);
            window.location.reload();
          }
        });
      } else {
        alert("INCORRECT USERNAME AND PASSWORD!");
      }
    });
  };

  const classes = useStyles();

  return (
    <Grid container component="main" className={classes.root}>
      <CssBaseline />
      <Grid item xs={false} sm={4} md={7} className={classes.image} />
      <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
        <div className={classes.paper}>
          <Avatar className={classes.avatar}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Sign in
          </Typography>
          <form onSubmit={onSubmit} className={classes.form}>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              value={state.username}
              id="email"
              onChange={onChange}
              label="Username"
              name="username"
              autoFocus
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="password"
              value={state.password}
              onChange={onChange}
              label="Password"
              type="password"
              id="password"
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              style={{}}
              className={classes.submit}
            >
              Sign In
            </Button>
            <Box mt={5}>
              <Copyright />
            </Box>
          </form>
        </div>
      </Grid>
    </Grid>
  );
}
