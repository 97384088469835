const routes = [
  {
    path: "/",
    exact: true,

    page_name: "Home",
  },
  {
    path: "/dashboard",
    exact: true,
    page_name: "Dashboard",
  },
  {
    path: "/fullscreenmap/",
    exact: true,
    page_name: "Full Screen Map",
  },
  {
    path: "/booking/",
    exact: true,
    page_name: "Booking",
  },
  {
    path: "/driverList/",
    exact: true,
    page_name: "IndexDriver",
  },
  {
    path: "/clientList/",
    exact: true,
    page_name: "IndexPassengers",
  },
  {
    path: "/login/",
    exact: true,
    page_name: "Login",
  },
  {
    path: "/queueing/",
    exact: true,
    page_name: "IndexQueueing",
  },
  {
    path: "/privilege/",
    exact: true,
    page_name: "Privilege",
  },
  {
    path: "/partners/",
    exact: true,
    page_name: "IndexPartners",
  },
  {
    path: "/vehicles/",
    exact: true,
    page_name: "IndexVehicle",
  },
];
export default routes;
