import * as React from "react";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import Gmovers from "../../assets/gmovers.gif";
const renderEqualProps = (prevProps, nextProps) => {
  return JSON.stringify(prevProps) === JSON.stringify(nextProps);
};
const Loading = (props) => {
  const { ...param } = props;

  return (
    <Backdrop style={{ color: "#fff", zIndex: 99999999999 }} open={param.open}>
      <img
        src={Gmovers}
        style={{ width: 350, height: 350, objectFit: "contain" }}
      />
    </Backdrop>
  );
};
export default React.memo(Loading, renderEqualProps);
