import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import { applyMiddleware, createStore } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import { createMigrate, persistReducer, persistStore } from "redux-persist";
import { PersistGate } from "redux-persist/integration/react";
import storage from "redux-persist/lib/storage";
import thunk from "redux-thunk";
import App from "./App";
import rootReducer from "./components/apps/combineReducer/combineReducer";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
const migrations = {
  0: (state) => {
    return {
      ...state,
    };
  },
};
const persistConfig = {
  key: "primary",
  version: 0,
  storage,
  debug: true,
  stateReconciler: false,
  migrate: createMigrate(migrations, { debug: true }),
};
const finalReducer = persistReducer(persistConfig, rootReducer);

const devTools =
  process.env.NODE_ENV === "production"
    ? applyMiddleware(thunk)
    : composeWithDevTools(applyMiddleware(thunk));
const store = createStore(finalReducer, devTools);
const persistore = persistStore(store);

ReactDOM.createRoot(document.getElementById("root")).render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistore}>
      <App />
    </PersistGate>
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
