import { Tooltip, Typography } from "@material-ui/core";
import AppBar from "@material-ui/core/AppBar";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import Divider from "@material-ui/core/Divider";
import Drawer from "@material-ui/core/Drawer";
import IconButton from "@material-ui/core/IconButton";
import Link from "@material-ui/core/Link";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Toolbar from "@material-ui/core/Toolbar";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import HomeOutlinedIcon from "@material-ui/icons/HomeOutlined";
import MenuBookIcon from "@material-ui/icons/MenuBook";
import MapIcon from "@material-ui/icons/Map";
import MenuIcon from "@material-ui/icons/Menu";
import MuiAlert from "@material-ui/lab/Alert";
import clsx from "clsx";
import moment from "moment";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link as NewLink } from "react-router-dom";
import { GetDashboardDetails } from "../apps/Functions/functions";
import LogoIMG from "../media/glogo.png";
import AccessibleIcon from "@material-ui/icons/Accessible";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
const drawerWidth = 240;
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  grow: {
    flexGrow: 1,
  },
  appBar: {
    background: "#6AB04C",
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  hide: {
    display: "none",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    height: "100%",
    overflow: "auto",
  },
  drawerPaper: {
    width: drawerWidth,
    height: "100%",
    overflow: "auto",
  },
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: "flex-end",
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
  backdrop: {
    zIndex: 99,
    color: "#fff",
  },
  sectionMobile: {
    display: "flex",
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
}));
function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}
export default function PersistentDrawerLeft() {
  const classes = useStyles();
  const theme = useTheme();
  const matches = useMediaQuery("(max-width:959px)");
  const [anchorEl, setAnchorEl] = React.useState(null);
  const menuId = "primary-search-account-menu";
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);
  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);
  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const dispatch = useDispatch();
  const dispatch_data = (type, data) => {
    dispatch({
      type: type,
      data: data,
    });
  };
  const [state, setState] = React.useState({
    timesheet: false,
    attendance: false,
    payroll: false,
    open: false,
    system: false,
    application: false,
    retro: false,
    adjustments: false,
    payrollSettings: false,
    equipment: false,
    memo: false,
    vehicle: false,
    request_logs: [],
    attendance_pages: [],
    payroll_pages: [],
    retro_pages: [],
    payroll_adjustments_pages: [],
    payroll_settings_pages: [],
    app_settings_pages: [],
    system_settings_pages: [],
    vehicle_pages: [],
    uploading: false,
    upload_pages: [],
    equipments: [],
    memorandum: [],
    vertical: "top",
    horizontal: "center",
    req_count: 0,
  });
  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const imglogo = LogoIMG;
  const handleDrawerOpen = () => {
    setState({
      ...state,
      open: true,
    });
  };
  const handleDrawerClose = () => {
    setState({
      ...state,
      timesheet: false,
      attendance: false,
      payroll: false,
      open: false,
      system: false,
      application: false,
    });
  };
  const logout = () => {
    // localStorage.clear();
    // window.location.replace(websitelink);
    //  setTimeout(() => {
    //   window.location.reload();
    // }, 500);
  };
  const GetLandingDetails = () => {
    let data = {
      start: moment(new Date()).format("Y-MM-DD"),
      end: moment(new Date()).format("Y-MM-DD"),
    };
    GetDashboardDetails(data).then((response) => {
      let idle = 0;
      let onmove = 0;
      let lt = "";
      let lng = "";
      let orglt = "";
      let orglng = "";
      let deslt = "";
      let deslng = "";
      let rev = 0;
      let vehicles = [];
      response.trips.map((val) => {
        if (val.jo_status === "Dropped off") {
          if (val.vehicle_type === "Taxi") {
            rev += 13;
          } else {
            rev += parseFloat(val.jo_fare * 0.2);
          }
        }
      });
      response.drivers.map((value) => {
        let stat = "Idle";
        if (value.location_activity_id !== "0") {
          stat = "On the move";
        }
        let cons = value.location_latlng.split(",");
        lt = cons[0];
        lng = cons[1];
        value.lat = lt;
        value.long = lng;
        value.status = stat;
      });
      response.trips.map((value) => {
        let origin = value.origin_latlng.split(",");
        let destination = value.destination_latlng.split(",");
        orglt = origin[0];
        orglng = origin[1];
        deslt = destination[0];
        deslng = destination[1];
        value.orlat = orglt;
        value.orlong = orglng;
        value.deslat = deslt;
        value.deslong = deslng;
      });
      response.drivers.map((value) => {
        let match = false;
        if (value.location_activity_id === "0") {
          idle++;
        } else if (value.location_activity_id !== "0") {
          onmove++;
        }
        vehicles.map((val) => {
          if (val.vehicle_type == value.vehicle_type) {
            match = true;
          }
        });
        if (!match) {
          vehicles.push({
            vehicle_type: value.vehicle_type,
          });
        }
      });
      const driverpie = [
        {
          name: "On the Move",
          value: onmove,
        },
        { name: "Idle", value: idle },
      ];
      dispatch_data("GetDashboardDetails", response);
      dispatch_data("DriverpieArray", driverpie);
      dispatch_data("UniqueVehicles", vehicles);
      dispatch_data("TodayRev", rev.toFixed(2));
      setTimeout(() => {
        GetLandingDetails();
      }, 60000);
    });
  };
  React.useEffect(() => {
    GetLandingDetails();
  }, []);

  return (
    <>
      <CssBaseline />
      <AppBar position="fixed" className={clsx(classes.appBar)}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            className={clsx(classes.menuButton, state.open && classes.hide)}
          >
            <MenuIcon />
          </IconButton>
          {/* {!matches ? <> */}
          <Link href="/#/">
            <Avatar
              variant="square"
              alt="picture"
              src={imglogo}
              style={{ width: 60, height: "auto" }}
            />
          </Link>
          <Typography variant="h6" style={{ fontWeight: 600 }}>
            GREEN MOVERS
          </Typography>

          <div className={classes.grow} />
        </Toolbar>
      </AppBar>
      <Drawer
        className={classes.drawer}
        variant="persistent"
        anchor="left"
        open={state.open}
        classes={{ paper: classes.drawerPaper }}
      >
        <div className={classes.drawerHeader}>
          <Button
            variant="contained"
            className={classes.button}
            onClick={handleDrawerClose}
            style={{ backgroundColor: "#6AB04C", color: "white" }}
          >
            Hide
            {theme.direction === "ltr" ? (
              <ChevronRightIcon />
            ) : (
              <ChevronRightIcon />
            )}
          </Button>
        </div>
        <List
          component="nav"
          className={classes.liststyle}
          aria-label="mailbox folders"
        >
          <NewLink to="/" style={{ textDecoration: "none", color: "black" }}>
            <ListItem button onClick={handleDrawerClose}>
              <ListItemIcon>
                <Tooltip title="Dashboard">
                  <HomeOutlinedIcon style={{ color: "#6AB04C" }} />
                </Tooltip>
              </ListItemIcon>
              <ListItemText primary="Dashboard" />
            </ListItem>
          </NewLink>
          <Divider />
          <NewLink
            to="/fullscreenmap/"
            style={{ textDecoration: "none", color: "black" }}
          >
            <ListItem button onClick={handleDrawerClose}>
              <ListItemIcon>
                <Tooltip title="Map">
                  <MapIcon style={{ color: "#6AB04C" }} />
                </Tooltip>
              </ListItemIcon>
              <ListItemText primary="Map" />
            </ListItem>
          </NewLink>
          <Divider />
          <NewLink
            to="/booking/"
            style={{ textDecoration: "none", color: "black" }}
          >
            <ListItem button onClick={handleDrawerClose}>
              <ListItemIcon>
                <Tooltip title="Booking">
                  <MenuBookIcon style={{ color: "#6AB04C" }} />{" "}
                </Tooltip>
              </ListItemIcon>
              <ListItemText primary="Booking" />
            </ListItem>
          </NewLink>
          <Divider />
          <NewLink
            to="/driverList/"
            style={{ textDecoration: "none", color: "black" }}
          >
            <ListItem button onClick={handleDrawerClose}>
              <ListItemIcon>
                <Tooltip title="Drivers">
                  <AccessibleIcon style={{ color: "#6AB04C" }} />
                </Tooltip>
              </ListItemIcon>
              <ListItemText primary="Drivers" />
            </ListItem>
          </NewLink>
          <Divider />
          <NewLink
            to="/clientList/"
            style={{ textDecoration: "none", color: "black" }}
          >
            <ListItem button onClick={handleDrawerClose}>
              <ListItemIcon>
                <Tooltip title="Clients">
                  <AccountCircleIcon style={{ color: "#6AB04C" }} />
                </Tooltip>
              </ListItemIcon>
              <ListItemText primary="Clients" />
            </ListItem>
          </NewLink>
          <Divider />
          {/* <NewLink to='/' style={{ textDecoration: 'none', color: 'black' }}>
            <ListItem
              button
              onClick={() => {
                logout()
              }}
            >
              <ListItemIcon>
                <ExitToAppIcon style={{ color: '#6AB04C' }} />
              </ListItemIcon>
              <ListItemText primary='Log out' />
            </ListItem>
          </NewLink>
          <Divider /> */}
        </List>
      </Drawer>
    </>
  );
}
