import { Grid } from "@material-ui/core";
import React from "react";
import VehicleItemDisplay from "./VehicleItemDisplay";
const height = window.innerHeight;
const VehicleList = (props) => {
  return (
    <div
      style={{ height: height * 0.65, overflowY: "auto", overflowX: "hidden" }}
    >
      <Grid container spacing={1}>
        {props.queueList.map((val, index) => {
          return (
            <Grid item xs={12} md={12} key={index}>
              <VehicleItemDisplay values={val} color={"#f39c12"} />
            </Grid>
          );
        })}
      </Grid>
    </div>
  );
};

export default VehicleList;
