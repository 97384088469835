const initialState = {
  isOpenRegisterCounter: false,
  timer: false,
  resetTimer: false,
  refeshDisplay: false,
  messageNoti: "",
};
const dataReducer = (state = initialState, action) => {
  switch (action.type) {
    case "ACTION_QUEUEING":
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};
export default dataReducer;
