import axios from "axios";
import { image } from "./url";
let serverurl = "https://api.mobile.pacificweb.com.ph/";
let key = "?key=PocketHR@20190208&type=web";
const CancelToken = axios.CancelToken;
let source = CancelToken.source();
export function getData(method, param) {
  return new Promise((resolve, reject) => {
    axios
      .post(serverurl + method + key, {
        param,
      })
      .then((res) => {
        resolve(res.data);
      });
  });
}
export function cancelRequest() {
  source.cancel("Operation canceled by the user.");
  source = CancelToken.source();
}
export const imageUrl = serverurl;
