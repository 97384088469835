import { combineReducers } from "redux";
import navigation_reducer from "../reducer/navigation_reducer";
import booking_reducer from "../reducer/booking_reducer";
import driver_reducer from "../reducer/driver_reducer";
import drivers_booking_reducer from "../reducer/drivers_booking_reducer";
import queueing_reducer from "../reducer/queueing_reducer";
import privilege_reducer from "../reducer/privilege_reducer";
import partner_reducer from "../reducer/partner_reducer";
import vehicle_reducer from "../reducer/vehicle_reducer";
export default combineReducers({
  navigation_reducer: navigation_reducer,
  booking_reducer: booking_reducer,
  driver_reducer: driver_reducer,
  drivers_booking_reducer: drivers_booking_reducer,
  queueing_reducer: queueing_reducer,
  privilege_reducer: privilege_reducer,
  partner_reducer: partner_reducer,
  vehicle_reducer: vehicle_reducer,
});
